const utils = require("../utils");
const eventTracker = utils.eventTracker;

var mrMapImageryControl = function (zoomLevels) {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "map-layers",
		containerTitle: "View high-resolution imagery",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerContentBaseClass: "mr-map-controls-trigger-content",
		triggerContentType: "text",
		triggerContentHTML: "High-Res Imagery",
		triggerLoadingContentHTML: "Fetching Imagery...",
		dropdownContainerBaseClass: "mr-map-controls-dropdown",
		dropdownContainerClassNames: "",
		selectBaseClass: "mr-map-controls-select",
		selectClassNames: "",
		optionBaseClass: "mr-map-controls-option",
		optionClassNames: ""
	};

	self.stateModel = {
		active: false,
		visible: true,
		enabled: false,
		controls: {},
		lastKnownCenter: null,
		currentDate: null,
		currentId: null,
		currentLayer: null,
		currentMaxZoom: Math.max.apply(null, zoomLevels),
		currentOrientation: null,
		currentOverlay: null,
		currentOverlayType: null,
		currentProvider: null,
		currentResolution: null,
		orientations: ["North", "East", "South", "West", "Vertical"],
		zoomLevels: zoomLevels,
		zoomListener: null
	};

	self.layerMap = {
		"bluesky-ultra-g": "bluesky",
		"bluesky-ultra": "bluesky",
		"bluesky-high": "bluesky",
		graysky: "graysky",
		"graysky-g": "graysky"
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));
	self.zoomLevels = zoomLevels;
	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerContent = $("<span></span>");
	self.$mapControlDropdownContainer = $("<div></div>");
	self.$mapControlSelect = $("<select></select>");
	self.$mapControlOption = $("<option></option>");

	self.init = function (mapController, options) {
		if (!mapController) {
			console.log("mrMapImageryControl error; no mapController specified");

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"mrMapImageryControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("mrMapImageryControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"mrMapImageryControl error: Cannot create duplicate mrMapImageryControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr("title", self.options.containerTitle);

		if (self.options.enabled === false) {
			self.$mapControlContainer.addClass("disabled");
			self.state.enabled = false;
		} else {
			self.state.enabled = true;
		}

		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);

		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);

		self.$mapControlTriggerContent.addClass(
			self.options.triggerContentBaseClass
		);
		self.$mapControlTriggerContent.html(self.options.triggerContentHTML);

		self.$mapControlDropdownContainer.addClass(
			self.options.dropdownContainerBaseClass +
				" " +
				self.options.dropdownContainerClassNames
		);

		self.$mapControlSelect.addClass(
			self.options.selectBaseClass + " " + self.options.selectClassNames
		);

		self.$mapControlDropdownContainer.append(self.$mapControlSelect);

		self.$mapControlTrigger.append(self.$mapControlTriggerContent);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);
		self.$mapControlContainer.append(self.$mapControlDropdownContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.attachTriggerClick();
		self.attachSelectChange();
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};

	self.reset = function () {
		self.hideCurrentOverlay();
		self.state.currentOverlay = null;
		self.disable();
		self.deactivate();

		for (var control in self.state.controls) {
			self.state.controls[control].removeClass("active");
		}
	};

	self.enable = function () {
		self.state.enabled = true;
		self.$mapControlContainer.removeClass("disabled");
	};

	self.disable = function () {
		self.state.enabled = false;
		self.$mapControlContainer.addClass("disabled");
	};

	self.activate = function () {
		self.state.active = true;
		self.$mapControlContainer.addClass("active");
	};

	self.deactivate = function () {
		self.state.active = false;
		self.$mapControlContainer.removeClass("active");
	};

	self.setLoadingState = function () {
		self.$mapControlTriggerContent.html(self.options.triggerLoadingContentHTML);
	};

	self.setLoadedState = function () {
		self.$mapControlTriggerContent.html(self.options.triggerContentHTML);
	};

	self.populateSelect = function (options) {
		self.$mapControlSelect.empty();

		self.$mapControlSelect.append(
			$('<option default selected value="">Select a Date</option>')
		);

		for (var option in options) {
			var $optionObject = self.$mapControlOption.clone();
			$optionObject.val(options[option].date.value);
			$optionObject.html(
				options[option].date.display +
					(options[option].layer
						? " " + self.layerMap[options[option].layer]
						: "")
			);
			$optionObject.attr("data-provider", options[option].provider);
			$optionObject.attr(
				"data-orientations",
				JSON.stringify(options[option].orientations)
			);
			$optionObject.attr("data-id", options[option].id);

			if (options[option].layer) {
				$optionObject.attr("data-layer", options[option].layer);
			}

			if (options[option].maxZoom) {
				$optionObject.attr("data-max-zoom", options[option].maxZoom);
			}

			if (options[option].overlayType) {
				$optionObject.attr("data-overlay-type", options[option].overlayType);
			}

			if (options[option].resolution) {
				$optionObject.attr("data-resolution", options[option].resolution);
			}

			self.$mapControlSelect.append($optionObject);
		}
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();

			if (self.state.enabled === true) {
				if (self.$mapControlContainer.hasClass("active")) {
					self.deactivate();
					self.hideCurrentOverlay();
				} else {
					self.activate();

					if (self.state.currentOverlay) {
						self.activateLayer();
					}
				}
			}
		});
	};

	self.attachSelectChange = function () {
		self.$mapControlSelect.on("change", function (e) {
			e.preventDefault();

			if (!$(this).val()) {
				self.hideCurrentOverlay();
				return;
			}

			let maxZoom;
			if (
				Math.max.apply(null, self.zoomLevels) >
				parseInt($(this).find("option:selected").attr("data-max-zoom"))
			) {
				maxZoom = parseInt(
					$(this).find("option:selected").attr("data-max-zoom")
				);
			} else {
				maxZoom = Math.max.apply(null, window.mrState.imagery.zoomLevels);
			}

			self.state.currentDate = $(this).find("option:selected").val() || null;
			self.state.currentProvider =
				$(this).find("option:selected").attr("data-provider") || null;
			self.state.currentOrientation =
				$(this).find("option:selected").attr("data-orientation") || null; //hard-coding until phase 2
			self.state.currentLayer =
				$(this).find("option:selected").attr("data-layer") || null;
			self.state.currentId =
				$(this).find("option:selected").attr("data-id") || null;
			self.state.currentOverlayType =
				$(this).find("option:selected").attr("data-overlay-type") || null;
			self.state.currentResolution =
				$(this).find("option:selected").attr("data-resolution") || null;
			self.state.currentMaxZoom = maxZoom;

			self.activateLayer();
		});
	};

	self.calculateZoom = function () {
		let zoom = Math.min(Object.keys(self.zoomLevels));

		return zoom;
	};

	self.activateLayer = function () {
		var params = {
			provider: self.state.currentProvider,
			date: self.state.currentDate
		};

		if (self.state.currentOrientation) {
			params.orientation = self.state.currentOrientation;
		}

		if (self.state.currentLayer) {
			params.layer = self.state.currentLayer;
		}

		if (self.state.currentId) {
			params.id = self.state.currentId;
		}

		if (self.state.currentResolution) {
			params.resolution = self.state.currentResolution;
		}

		self.hideCurrentOverlay();

		switch (self.state.currentOverlayType) {
			case "image":
				self.state.currentOverlay = self.mapController.imageryImages.getImage(
					window.currentPoiInfo.poi,
					params
				);
				break;
			case "tile":
			default:
				self.state.currentOverlay = self.mapController.imageryTiles.getTiles(
					params,
					self.zoomLevels
				);
				break;
		}

		var currentZoom = self.mapController.map.getZoom();
		self.mapController.mapTypeControl.setMapType("roadmap");
		self.mapController.map.setZoom(currentZoom);

		self.showOverlay();
	};

	self.showOverlay = function () {
		let label = {
			_id: window.id,
			layer: self.layerMap[self.state.currentLayer] || null,
			provider: self.state.currentProvider,
			request_type: utils.getAuthenticationType()
		};

		eventTracker("user_action", "view_imagery", label);

		// set max zoom based on coverage response
		var lowestZoom = Math.min.apply(null, self.zoomLevels);
		var highestZoom = self.state.currentMaxZoom;

		// Zoom to lowest or highest zoom level if current zoom is out of coverage range
		if (self.mapController.map.getZoom() < lowestZoom) {
			self.mapController.map.setZoom(lowestZoom);
		}

		if (self.mapController.map.getZoom() > highestZoom) {
			self.mapController.map.setZoom(highestZoom);
		}

		// Disable map interaction
		self.mapController.disablePanning();
		self.limitZoomRange();

		// Detach zoom listener, center map, and re-attach zoom listener
		self.detachZoomListener();

		self.mapController.map.setCenter({
			lat: self.mapController.currentPoi.lat,
			lng: self.mapController.currentPoi.lng
		});

		window.eventBus.publish("map:poi:changed");

		self.attachZoomListener();

		switch (self.state.currentOverlay.type) {
			case "image":
				self.state.currentOverlay.setMap(self.mapController.map);
				break;
			case "tile":
				self.mapController.map.overlayMapTypes.insertAt(
					0,
					self.state.currentOverlay
				);
				break;
		}
	};

	self.hideCurrentOverlay = function () {
		if (self.state.currentOverlay) {
			// Unlock user interface
			self.mapController.enablePanning();
			self.mapController.mapZoomInControl.enable();
			self.mapController.mapZoomOutControl.enable();
			self.detachZoomListener();

			switch (self.state.currentOverlay.type) {
				case "image":
					self.state.currentOverlay.setMap(null);
					break;
				case "tile":
				default:
					if (
						self.mapController.map.overlayMapTypes &&
						self.mapController.map.overlayMapTypes.length > 0
					) {
						self.mapController.map.overlayMapTypes.forEach(function (
							obj,
							index
						) {
							if (
								obj &&
								obj.namespace.toLowerCase() ===
									self.state.currentOverlay.namespace.toLowerCase() &&
								obj.name.toLowerCase() ===
									self.state.currentOverlay.name.toLowerCase()
							) {
								self.mapController.map.overlayMapTypes.removeAt(index);
							}
						});
					}

					break;
			}
		}
	};

	self.attachZoomListener = function () {
		self.state.zoomListener = google.maps.event.addListener(
			self.mapController.map,
			"zoom_changed",
			function () {
				self.limitZoomRange();
			}
		);
	};

	self.detachZoomListener = function () {
		if (self.state.zoomListener) {
			self.state.zoomListener.remove();
		}
	};

	self.limitZoomRange = function () {
		var currentZoom = self.mapController.map.getZoom();

		if (currentZoom < self.state.currentMaxZoom) {
			self.mapController.mapZoomInControl.enable();
		} else {
			self.mapController.mapZoomInControl.disable();
		}

		if (self.zoomLevels.indexOf(currentZoom - 1) > -1) {
			self.mapController.mapZoomOutControl.enable();
		} else {
			self.mapController.mapZoomOutControl.disable();
		}
	};
};

module.exports = mrMapImageryControl;
