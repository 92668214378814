const BatchClient = require("../../clients/batch-client.js");
const utils = require("../../utils");
const sanitizeInput = utils.sanitizeInput;

function BatchHistoryModel(jwt, batchUrlBase, path, batches) {
	this.jwt = jwt;
	this.batchUrlBase = batchUrlBase;
	this.path = path;
	this.batches = batches || {};
}

BatchHistoryModel.prototype.add = function (batch) {
	if (!this.batches[batch.id]) this.batches[batch.id] = batch;

	this.updateStatus(batch);
};

BatchHistoryModel.prototype.makeConfigureUI = function (batch, statusLabel) {
	//  make assets
	var container = $("<div></div>");
	var statusContainer = $('<div class="status-container"></div>');
	var statusLabel = $('<div class="title"><p>' + statusLabel + "</p></div>");
	var actionsContainer = $('<div class="actions-container"></div>');

	var configureButton = $(
		'<div id="' +
			batch.id +
			'-configure-button" class="next-button configure-button btn"></div>'
	);
	var configureButtonIcon = $(
		'<i class="icon-cogs" alt="Configure Batch"></i>'
	);
	var configureButtonLabel = $('<div class="title">Configure</div>');

	var deleteButton = $(
		'<div id="' + batch.id + '-delete-button" class="delete-button btn"></div>'
	);
	var deleteButtonIcon = $('<i class="icon-trash" alt="Delete Batch"></i>');
	var deleteButtonLabel = $('<div class="title">Delete</div>');

	//  assembly line
	statusContainer.append(statusLabel);

	deleteButton.append(deleteButtonIcon);
	deleteButton.append(deleteButtonLabel);
	actionsContainer.append(deleteButton);

	configureButton.append(configureButtonIcon);
	configureButton.append(configureButtonLabel);
	actionsContainer.append(configureButton);

	container.append(statusContainer);
	container.append(actionsContainer);

	return container;
};

BatchHistoryModel.prototype.makeQueuedUI = function (batch, statusLabel) {
	//  make assets
	var container = $("<div></div>");
	var statusContainer = $('<div class="status-container"></div>');
	var statusLabel = $('<div class="title"><p>' + statusLabel + "</p></div>");
	var actionsContainer = $('<div class="actions-container"></div>');

	var deleteButton = $(
		'<div id="' + batch.id + '-delete-button" class="delete-button btn"></div>'
	);
	var deleteButtonIcon = $('<i class="icon-trash" alt="Delete Batch"></i>');
	var deleteButtonLabel = $('<div class="title">Delete</div>');

	//  assembly line
	statusContainer.append(statusLabel);

	deleteButton.append(deleteButtonIcon);
	deleteButton.append(deleteButtonLabel);
	actionsContainer.append(deleteButton);

	container.append(statusContainer);
	container.append(actionsContainer);

	return container;
};

BatchHistoryModel.prototype.makeProcessingUI = function (batch, statusLabel) {
	//  make assets
	var container = $("<div></div>");
	var statusContainer = $('<div class="status-container"></div>');
	var statusLabel = $('<div class="title"><p>' + statusLabel + "</p></div>");
	var progressContainer = $('<div class="progress-container"></div>');
	var progressBar = $(
		'<div class="mr-progress-bar"><div class="track"></div><div class="mr-progress"></div></div>'
	);
	var actionsContainer = $('<div class="actions-container"></div>');
	var cancelButton = $(
		'<div id="' + batch.id + '-cancel-button" class="cancel-button btn"></div>'
	);
	var cancelButtonIcon = $(
		'<i class="icon-cancel-circled" alt="Cancel Batch"></i>'
	);
	var cancelButtonLabel = $('<div class="title">Cancel</div>');

	//  assembly line
	statusContainer.append(statusLabel);
	progressContainer.append(progressBar);

	cancelButton.append(cancelButtonIcon);
	cancelButton.append(cancelButtonLabel);
	actionsContainer.append(cancelButton);

	container.append(statusContainer);
	container.append(progressContainer);
	container.append(actionsContainer);

	return container;
};

BatchHistoryModel.prototype.makeCompleteUI = function (batch, statusLabel) {
	//  make assets
	var batchClient = new BatchClient(this.batchUrlBase, this.path);
	var container = $("<div></div>");
	var statusContainer = $('<div class="status-container"></div>');
	var statusLabel = $('<div class="title"><p>' + statusLabel + "</p></div>");
	var actionsContainer = $('<div class="actions-container"></div>');

	var downloadButton = $(
		'<button id="' +
			batch.id +
			'-download-button" class="download-button btn" type="button"></button>'
	);

	var downloadButtonIcon = $(
		'<i class="icon-download" alt="Download Batch"></i>'
	);
	var downloadButtonLabel = $('<div class="title">Download</div>');
	var deleteButton = $(
		'<div id="' + batch.id + '-delete-button" class="delete-button btn"></div>'
	);
	var deleteButtonIcon = $('<i class="icon-trash" alt="Delete Batch"></i>');
	var deleteButtonLabel = $('<div class="title">Delete</div>');

	//  assembly line
	statusContainer.append(statusLabel);

	downloadButton.append(downloadButtonIcon);
	downloadButton.append(downloadButtonLabel);
	deleteButton.append(deleteButtonIcon);
	deleteButton.append(deleteButtonLabel);

	actionsContainer.append(deleteButton);
	actionsContainer.append(downloadButton);

	container.append(statusContainer);
	container.append(actionsContainer);

	return container;
};

BatchHistoryModel.prototype.makeCanceledUI = function (batch, statusLabel) {
	//  make assets
	var container = $("<div></div>");
	var statusContainer = $('<div class="status-container"></div>');
	var statusLabel = $('<div class="title"><p>' + statusLabel + "</p></div>");
	var actionsContainer = $('<div class="actions-container"></div>');
	var deleteButton = $(
		'<div id="' + batch.id + '-delete-button" class="delete-button btn"></div>'
	);
	var deleteButtonIcon = $('<i class="icon-trash" alt="Delete Batch"></i>');
	var deleteButtonLabel = $('<div class="title">Delete</div>');

	//  assembly line
	statusContainer.append(statusLabel);

	deleteButton.append(deleteButtonIcon);
	deleteButton.append(deleteButtonLabel);

	actionsContainer.append(deleteButton);

	container.append(statusContainer);
	container.append(actionsContainer);

	return container;
};

BatchHistoryModel.prototype.makeErrorUI = function (batch, statusLabel) {
	//  make assets
	var container = $("<div></div>");
	var statusContainer = $('<div class="status-container"></div>');
	var statusLabel = $('<div class="title"><p>' + statusLabel + "</p></div>");
	var actionsContainer = $('<div class="actions-container"></div>');
	var deleteButton = $(
		'<div id="' + batch.id + '-delete-button" class="delete-button btn"></div>'
	);
	var deleteButtonIcon = $('<i class="icon-trash" alt="Delete Batch"></i>');
	var deleteButtonLabel = $('<div class="title">Delete</div>');

	//  assembly line
	statusContainer.append(statusLabel);

	deleteButton.append(deleteButtonIcon);
	deleteButton.append(deleteButtonLabel);

	actionsContainer.append(deleteButton);

	container.append(statusContainer);
	container.append(actionsContainer);

	return container;
};

BatchHistoryModel.prototype.hookUpCancelButtonHandler = function (batch) {
	var self = this;
	$("#" + batch.id + "-cancel-button").one("click", function () {
		var success = function (response) {
			self.batches[batch.id] = response.data.batch;

			$("#" + batch.id + "-cancel-button").prop("disabled", false);
			$("#" + batch.id + "-cancel-button .spinner").remove();
			$("#" + batch.id + "-cancel-button")
				.find("i")
				.show();

			self.updateStatus(self.batches[batch.id]);
		};

		var error = function (error) {
			$("#" + batch.id + "-cancel-button").prop("disabled", false);
			$("#" + batch.id + "-cancel-button .spinner").remove();
			$("#" + batch.id + "-cancel-button")
				.find("i")
				.show();

			self.hookUpCancelButtonHandler(batch);
		};

		var batchClient = new BatchClient(self.batchUrlBase, self.path);
		batchClient.cancelById(batch, error, success);

		$("#" + batch.id + "-cancel-button").prop("disabled", true);
		$("#" + batch.id + "-cancel-button")
			.find("i")
			.hide();
		$("#" + batch.id + "-cancel-button").prepend(
			'<div class="spinner"><img src="../../images/ajax-spinner-gray.svg" alt="Ajax Spinner"></div>'
		);
	});
};

BatchHistoryModel.prototype.hookUpDeleteButtonHandler = function (batch) {
	var self = this;
	$("#" + batch.id + "-delete-button").one("click", function () {
		var success = function (response) {
			$("#" + batch.id + "-delete-button").prop("disabled", false);
			$("#" + batch.id + "-delete-button .spinner").remove();
			$("#" + batch.id + "-delete-button")
				.find("i")
				.show();

			delete self.batches[batch.id];
			$("#batch-" + batch.id).remove();
		};

		var error = function (error) {
			$("#" + batch.id + "-delete-button").prop("disabled", false);
			$("#" + batch.id + "-delete-button .spinner").remove();
			$("#" + batch.id + "-delete-button")
				.find("i")
				.show();

			self.hookUpDeleteButtonHandler(batch);
		};

		var batchClient = new BatchClient(self.batchUrlBase, self.path);
		batchClient.deleteById(batch, error, success);

		$("#" + batch.id + "-delete-button").prop("disabled", true);
		$("#" + batch.id + "-delete-button")
			.find("i")
			.hide();
		$("#" + batch.id + "-delete-button").prepend(
			'<div class="spinner"><img src="../../images/ajax-spinner-gray.svg" alt="Ajax Spinner"></div>'
		);
	});
};

BatchHistoryModel.prototype.hookUpDownloadButtonHandler = function (batch) {
	var self = this;
	$("#" + batch.id + "-download-button").on("click", function (event) {
		event.preventDefault();
		var batchClient = new BatchClient(self.batchUrlBase, self.path);
		batchClient.downloadById(batch.id, batch.fileName);
	});
};

BatchHistoryModel.prototype.updateStatus = function (batch) {
	//  remove old container
	$("#batch-" + batch.id).remove();

	var portfolioName = "";
	var statusLabel =
		batch.status.charAt(0).toUpperCase() +
		batch.status.slice(1, batch.status.length);

	//  build new
	var expiration = new Date(batch.expiration);
	var expirationDay = String(expiration.getDate());
	var expirationMonth = String(expiration.getMonth() + 1);

	if (expirationDay.length === 1) expirationDay = "0" + expirationDay;
	if (expirationMonth.length === 1) expirationMonth = "0" + expirationMonth;

	if (batch.portfolioName) {
		batch.portfolioName = sanitizeInput(batch.portfolioName);
		let nameNode =
			batch.portfolioLink && batch?.status?.toLowerCase() === "complete"
				? `<a href="${batch.portfolioLink}" rel="noopener" target="_blank">${batch.portfolioName}</a>`
				: batch.portfolioName;

		portfolioName = `<div class="portfolio-name"><i class="fas fa-briefcase"></i> ${nameNode} </div>`;
	}
	batch.fileName = sanitizeInput(batch.fileName);
	var divBatchName = '<div class="title"><p>' + batch.fileName + "</p></div>";
	var divExpirationDate =
		'<div class="exp-date"><p>Exp Date ' +
		expirationMonth +
		" / " +
		expirationDay +
		"</p></div>";
	var divBatchContainer = $(
		'<div id="batch-' +
			batch.id +
			'" class="batch-entry"><div class="name-exp">' +
			portfolioName +
			divBatchName +
			divExpirationDate +
			'</div><div class="batch-contents"></div></div>'
	);

	//  set contents
	var divBatchCategory;
	var divBatchContents;

	switch (batch.status) {
		case "pausing":
		case "paused":
		case "resuming":
			break;
		case "uploaded":
			divBatchCategory = $("#batch-history .content #queued");
			divBatchCategory.prepend(divBatchContainer);
			divBatchContents = this.makeConfigureUI(batch, statusLabel);
			$(divBatchContainer).find(".batch-contents")[0].innerHTML =
				divBatchContents[0].innerHTML;
			this.hookUpDeleteButtonHandler(batch);
			$("#" + batch.id + "-configure-button").on("click", function () {
				$(document).trigger("customEvent.batch.configureUploadedBatch", batch);
			});
			break;
		case "configured":
		case "queued":
			divBatchCategory = $("#batch-history .content #queued");
			divBatchCategory.prepend(divBatchContainer);
			divBatchContents = this.makeQueuedUI(batch, statusLabel);
			$(divBatchContainer).find(".batch-contents")[0].innerHTML =
				divBatchContents[0].innerHTML;
			this.hookUpDeleteButtonHandler(batch);
			break;
		case "processing":
			divBatchCategory = $("#batch-history .content #processing");
			divBatchCategory.prepend(divBatchContainer);
			divBatchContents = this.makeProcessingUI(batch, statusLabel);
			$(divBatchContainer).find(".batch-contents")[0].innerHTML =
				divBatchContents[0].innerHTML;
			this.hookUpCancelButtonHandler(batch);
			break;
		case "canceled":
			divBatchCategory = $("#batch-history .content #canceled");
			divBatchCategory.prepend(divBatchContainer);
			divBatchContents = this.makeCanceledUI(batch, statusLabel);
			$(divBatchContainer).find(".batch-contents")[0].innerHTML =
				divBatchContents[0].innerHTML;
			this.hookUpDeleteButtonHandler(batch);
			break;
		case "complete":
			divBatchCategory = $("#batch-history .content #completed");
			divBatchCategory.prepend(divBatchContainer);
			divBatchContents = this.makeCompleteUI(batch, statusLabel);
			$(divBatchContainer).find(".batch-contents")[0].innerHTML =
				divBatchContents[0].innerHTML;
			this.hookUpDownloadButtonHandler(batch);
			this.hookUpDeleteButtonHandler(batch);
			break;
		case "error":
			divBatchCategory = $("#batch-history .content #errored");
			divBatchCategory.prepend(divBatchContainer);
			divBatchContents = this.makeErrorUI(batch, statusLabel);
			$(divBatchContainer).find(".batch-contents")[0].innerHTML =
				divBatchContents[0].innerHTML;
			this.hookUpDeleteButtonHandler(batch);
			break;
	}

	if (batch.status === "processing")
		this.updateProgress(batch.id, batch.progress);
};

BatchHistoryModel.prototype.updateProgress = function (batchId, progress) {
	console.log(batchId + ": ", progress);
	$("#batch-" + batchId)
		.find(".mr-progress-bar .mr-progress")
		.css("width", progress + "%");
};

BatchHistoryModel.prototype.batchPoller = null;

BatchHistoryModel.prototype.initializeAccountSocket = function () {
	var self = this;

	self.socket.on("connect", function () {
		console.log("socket connected");
	});

	self.socket.on("disconnect", function () {
		console.log("socket disconnected");
	});

	self.socket.on("connect_timeout", function (error) {
		console.warn("socket connection timeout");
	});

	self.socket.on("connect_error", function (error) {
		console.error(error);
	});

	self.socket.on("error", function (error) {
		console.error(error);
	});

	self.socket.on("newBatch", function (batch) {
		console.log(`${batch.id}: new batch added for ${batch.fileName}`);

		if (!batch) {
			return;
		}

		if (!self[batch.id]) {
			self.add(batch);
		}
	});

	self.socket.on("status", function (statusUpdate) {
		console.log(statusUpdate.batchId + ": received status update");
		var batch = self.batches[statusUpdate.batchId];

		var updateBatchStatus = function (batch, statusUpdate) {
			batch.status = statusUpdate.status || batch.status;
			batch.progress = statusUpdate.progress || batch.progress;

			self.batches[batch.id] = batch;

			if (batch.portfolioName && batch.status === "complete") {
				self.updateStatus(batch);
				$(document).trigger("customEvent.batch.batchComplete");
				location.reload();
			} else {
				self.updateStatus(batch);
			}
		};

		if (!batch) {
			self.socket.emit("getById", statusUpdate.batchId, function (batch) {
				this.add(batch);
			});
		} else {
			updateBatchStatus(batch, statusUpdate);
		}
	});

	self.socket.on("progress", function (progressUpdate) {
		console.log(progressUpdate.batchId + ": received progress update");
		var batch = self.batches[progressUpdate.batchId];

		var updateBatchProgress = function (batch, progressUpdate) {
			batch.progress = progressUpdate.progress;

			if (
				batch.status !== "complete" &&
				batch.status !== "canceled" &&
				batch.status !== "processing"
			) {
				batch.status = "processing";
				self.updateStatus(batch);
			}

			if (progressUpdate.creditsRemaining)
				$(document).trigger(
					"customEvent.batch.updateCredits",
					progressUpdate.creditsRemaining
				);

			self.batches[batch.id] = batch;
			self.updateProgress(batch.id, batch.progress);
		};

		if (!batch) {
			self.socket.emit("getById", status.batchId, function (batch) {
				this.add(batch);
			});
		} else {
			updateBatchProgress(batch, progressUpdate);
		}
	});

	self.socket.on("idleDisconnect", function () {
		self.socket.close();
	});
};
module.exports = BatchHistoryModel;
